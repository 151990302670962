<template>
  <div
    style="
      display: flex;
      flex-direction: column;
      height: 100%;
      overflow: hidden;
    "
  >
    <Status></Status>
    <div
      style="
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        overflow: auto;
      "
    >
      <v-card tile>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="6" md="2">
                <v-text-field
                  label="Title"
                  dense
                  v-model="item.ui.title"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="2">
                <v-text-field
                  label="Description"
                  v-model="item.ui.description"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="2">
                <v-autocomplete
                  spellcheck="false"
                  v-model="item.ui.location"
                  :loading="loading"
                  :items="items"
                  :search-input.sync="search"
                  cache-items
                  flat
                  hide-no-data
                  hide-details
                  label="Location"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6" md="2">
                <!-- <v-file-input label="File input" prepend-icon="fa-camera"></v-file-input> -->
                <img :src="item.ui.src" style="max-height: 100px" />
                <!-- <v-text-field
                  label="Image"
                  dense
                  v-model="item.ui.src"
                ></v-text-field> -->
              </v-col>
              <v-col cols="12" sm="6" md="2">
                <v-row justify="center">
                  <v-expansion-panels>
                    <v-expansion-panel>
                      <v-expansion-panel-header
                        justify="space-between"
                        style="display: flex; justify-content: space-between"
                      >
                        Color
                        <div
                          class="color-chip"
                          :style="{ backgroundColor: item.ui.color }"
                        ></div>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-switch
                          v-model="item.ui.dark"
                          :label="
                            'Dark Theme: ' + (item.ui.dark ? 'On' : 'Off')
                          "
                        ></v-switch>
                        <v-color-picker
                          v-model="item.ui.color"
                          swatches-max-height="200"
                          hide-canvas
                          hide-inputs
                          hide-mode-switch
                          hide-sliders
                          show-swatches
                        ></v-color-picker>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-row>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-col cols="6" align="center">
            <v-btn dark color="brown" @click="cancel()">Cancel</v-btn>
          </v-col>
          <v-col cols="6" align="center">
            <v-btn dark color="green darken-2" @click="save()">Save</v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </div>
  </div>
</template>

<script>
import Status from "@/components/Status.vue";
import Device from "../model/Device";
import firebase from "../lib/firebase";
import { v4 as uuid } from "uuid";

export default {
  name: "DeviceSettings",
  components: {
    Status,
  },
  mounted() {
    let meta = this.$router.history.current.meta;
    if (!meta || !meta.showMenu) {
      this.$emit("hideMenu");
    }
  },

  data() {
    let tmp = new Device();
    Object.assign(tmp.ui, this.$store.device.ui);
    let list = tmp.ui.location ? [tmp.ui.location] : [];
    return {
      loading: false,
      items: list,
      search: null,
      select: null,
      sessionTimer: null,
      sessionID: uuid(),
      item: tmp,
    };
  },
  watch: {
    search(val) {
      val && val !== this.select && this.querySelections(val);
    },
  },
  methods: {
    async querySelections(v) {
      if (v.length < 4 || v == this.item.ui.location) {
        return;
      }

      clearTimeout(this.sessionTimer);
      this.sessionTimer = setTimeout(() => {
        this.sessionID = uuid();
      }, 60000);

      this.loading = true;
      let addMessage = firebase.functions().httpsCallable("maps_address");
      addMessage({ session: this.sessionID, input: v })
        .then((result) => {
          let data = [];
          for (let r of result.data) {
            data.push(r.main_text + " " + r.secondary_text);
          }
          console.log(result);
          this.items = data;
        })
        .catch((e) => {
          this.items = [];
          console.log("err", e);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async append() {
      let target = this.$store.device;
      let devices = await this.$store.db.devices.get();
      for (let d of devices) {
        if (d.id == target.id) {
          //Already have device so no need to add it
          return;
        }
      }
      await this.$store.db.devices.add(target);
    },
    async cancel() {
      let devices = this.$store.db.devices.get();
      if (!devices || devices.length == 0) {
        this.$router.push({ name: "Scan" });
      } else {
        this.$router.push({ name: "Unlock" });
      }
    },
    async save() {
      this.$store.device.ui = this.item.ui;
      await this.append();
      this.$router.push({ name: "Unlock" });
    },
  },
};
</script>

<style>
.color-chip {
  margin-left: 1em;
  border-radius: 3px;
  max-width: 50px;
  height: 18px;
  border: 1px solid #999;
}
</style>